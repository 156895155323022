<template>
  <v-btn text elevation="0" :to="route">
    <v-icon>mdi-chevron-left</v-icon>
    <span class="pl-3 text-h6">{{ $t("back") }}</span>
  </v-btn>
</template>

<script>
export default {
  name: "BackButtonComponent",
  props: {
    route: { type: Object, required: true },
  },
};
</script>
